import React, { FC } from "react";

interface StarProps {
  rating: number;
  disable?: boolean;
}

const Star: FC<StarProps> = ({ rating, disable = true }) => {
  return (
    <div>
      <svg
        aria-hidden="true"
        style={{
          position: "absolute",
          width: 0,
          height: 0,
          overflow: "hidden",
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <symbol id="icon-star" viewBox="0 0 26 28">
            <path d="M26 10.109c0 0.281-0.203 0.547-0.406 0.75l-5.672 5.531 1.344 7.812c0.016 0.109 0.016 0.203 0.016 0.313 0 0.406-0.187 0.781-0.641 0.781-0.219 0-0.438-0.078-0.625-0.187l-7.016-3.687-7.016 3.687c-0.203 0.109-0.406 0.187-0.625 0.187-0.453 0-0.656-0.375-0.656-0.781 0-0.109 0.016-0.203 0.031-0.313l1.344-7.812-5.688-5.531c-0.187-0.203-0.391-0.469-0.391-0.75 0-0.469 0.484-0.656 0.875-0.719l7.844-1.141 3.516-7.109c0.141-0.297 0.406-0.641 0.766-0.641s0.625 0.344 0.766 0.641l3.516 7.109 7.844 1.141c0.375 0.063 0.875 0.25 0.875 0.719z"></path>
          </symbol>
          <symbol id="icon-star-half" viewBox="0 0 13 28">
            <path d="M13 0.5v20.922l-7.016 3.687c-0.203 0.109-0.406 0.187-0.625 0.187-0.453 0-0.656-0.375-0.656-0.781 0-0.109 0.016-0.203 0.031-0.313l1.344-7.812-5.688-5.531c-0.187-0.203-0.391-0.469-0.391-0.75 0-0.469 0.484-0.656 0.875-0.719l7.844-1.141 3.516-7.109c0.141-0.297 0.406-0.641 0.766-0.641v0z"></path>
          </symbol>
        </defs>
      </svg>
      <div className="comment-stars">
        {Array.from({ length: 10 }, (_, i) => {
          const value = 5 - i * 0.5;
          const id = `rating-${value}`;
          const isHalf = value % 1 !== 0;
          const svgId = isHalf ? "icon-star-half" : "icon-star";

          return (
            <React.Fragment key={id}>
              <input
                className="comment-stars-input"
                type="radio"
                name="rating"
                value={value}
                id={id}
                defaultChecked={value === rating}
                disabled={disable}
              />
              <label
                className={`comment-stars-view ${isHalf ? "is-half" : ""}`}
                htmlFor={id}
              >
                <svg className={`icon icon-${svgId}`}>
                  <use xlinkHref={`#${svgId}`}></use>
                </svg>
              </label>
            </React.Fragment>
          );
        })}
      </div>
      <style jsx>{`
        .comment-stars {
          display: flex;
        }
        .comment-stars-input {
          display: none;
        }
        .comment-stars-view {
          cursor: pointer;
        }
        .icon {
          width: 24px;
          height: 24px;
        }
        .is-half {
          cursor: default; /* Disable pointer cursor for half stars */
        }
      `}</style>
    </div>
  );
};

export default Star;
