import React, { useState, useRef, useEffect } from "react";
import DialogWithBackdrop from "../molecules/dialogBackdrop";
import Star from "../atoms/star";
// import ReCaptcha from "../atoms/recaptchaV3";
import ReCaptcha from "../atoms/recaptcha";
import { useFormState } from "react-dom";
import axios from "axios";
import StarInput from "../atoms/starInput";
import { getOrCreateIdentifier } from "../../helpers/identifierHelper";
import { toast } from "react-toastify";
import { PostRatingModel } from "@/src/models/ratingModel";
import RatingRepository from "@/src/repositories/ratingRepository";

interface RatingDialogProps {
  onClose: () => void;
}

const RatingDialog: React.FC<RatingDialogProps> = ({ onClose }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [identifier, setIdentifier] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [hasError, setHasError] = useState<boolean>(false);

  const ratingRepository = new RatingRepository();

  const handleVerifyRecaptcha = (token: string | null) => {
    setRecaptchaToken(token);
  };

  useEffect(() => {
    const id = getOrCreateIdentifier();
    setIdentifier(id);
  }, []);

  // const [state, submitRating] = useFormState(postRating, initialState);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!recaptchaToken) {
      toast.info("Silahkan lengkapi reCaptcha");
      return;
    }

    /** Check Captcha Via API not use 
    // const response = await fetch("/api/verify-recaptcha", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ token: recaptchaToken }),
    // });

    // const result = await response.json();
    // if (!result.success) {
    //   toast.error("Verifikasi gagal, silahkan ulang kembali");
    //   return;
    // }
    */

    if (!formRef.current) return;

    if (rating === 0) {
      setHasError(true);
      toast.info("Silahkan beri rating sebelum mengirim ulasan.");
      return;
    }

    const ratingInputs = formRef.current.querySelectorAll<HTMLInputElement>(
      'input[type="radio"][name="rating"]',
    );
    const ratingInputsLength = ratingInputs.length;
    const selectedRating = Array.from(ratingInputs).find(
      (input) => input.checked,
    )?.value;

    // alert(rating);

    const postData: PostRatingModel = {
      captcha: recaptchaToken,
      identifier: identifier as string,
      rating: Number(rating),
      name,
      email,
      comment,
      browser: navigator.userAgent,
      // ip: "", // you can fetch IP address separately if required
    };

    try {
      const post = await ratingRepository.post(postData);
      // console.log(post);

      if (post.code === 200 && post.status === "OK") {
        toast.success("Ulasan anda sudah terkirim. Terima Kasih");
        window.dispatchEvent(new Event("refreshRating"));
        onClose();
      }
    } catch (e) {
      toast.error("Gagal memberi ulasan");
    }

    if (formRef.current) {
      // alert(`Terima kasih telah memberi rating: ${ratingInputs} kepada kami.`);
      // toast.success("Ulasan anda sudah terkirim. Terima Kasih");
      // window.location.reload();
    }
  };

  return (
    <DialogWithBackdrop
      title="Nilai Situs JDIH DPRD Provinsi Bali"
      onClose={onClose}
    >
      <form
        className="flex flex-col items-start"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <div className="-rotate-360 mx-4 mb-3 mt-2 scale-150 transform-gpu px-4">
          <StarInput
            rating={0}
            disable={false}
            setRating={setRating}
            hasError={hasError}
          />
        </div>
        <input
          type="text"
          className="my-2 w-full border p-2"
          placeholder="Nama"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="text"
          className="my-2 w-full border p-2"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <textarea
          className="my-2 w-full border p-2"
          placeholder="Komentar"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
        ></textarea>
        <div className="my-2 w-full">
          <ReCaptcha onVerify={handleVerifyRecaptcha} />
          {/* <ReCaptcha action="submit_form" onVerify={handleVerify} /> */}
        </div>
        <button
          type="submit"
          className="w-full rounded-md bg-primary py-2 text-white"
        >
          Kirim Ulasan
        </button>
      </form>
    </DialogWithBackdrop>
  );
};

export default RatingDialog;
