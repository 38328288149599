import axios from "axios";
import { BaseResponseModel } from "../models/baseResponseModel";
import { AnalyticsModel } from "../models/analyticsModel";

export default class AnalyticsRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
  }

  async fetchAnalytics(): Promise<BaseResponseModel<AnalyticsModel>> {
    try {
      const response = await axios.get(`${this.baseUrl}/analytics/all`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
