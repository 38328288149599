//Component for google recaptcha v2
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface ReCaptchaProps {
  onVerify: (token: string | null) => void;
}

const ReCaptcha: React.FC<ReCaptchaProps> = ({ onVerify }) => {
  const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "";

  if (!siteKey) {
    throw new Error("Google reCAPTCHA site key is not defined.");
  }

  return (
    <div className="my-4">
      <ReCAPTCHA sitekey={siteKey} onChange={onVerify} />
    </div>
  );
};

export default ReCaptcha;
