import React from "react";

export default function SocialIcon() {
  return (
    <div className="flex space-x-4">
      <a
        href="https://www.facebook.com"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Temukan kita di Facebook"
        className="flex h-7 w-7 items-center justify-center rounded-full border text-center"
      >
        <i className="fab fa-facebook-f"></i>
      </a>

      <a
        href="https://www.twitter.com"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Follow kita di Twitter"
        className="flex h-7 w-7 items-center justify-center rounded-full border text-center"
      >
        <i className="fab fa-twitter"></i>
      </a>
      <a
        href="https://www.instagram.com/humasdewanbali/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Follow kita di Instagram"
        className="flex h-7 w-7 items-center justify-center rounded-full border text-center"
      >
        <i className="fab fa-instagram"></i>
      </a>
    </div>
  );
}
