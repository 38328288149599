import { useEffect, useState } from "react";

export function useRatingRefresh() {
  const [ratingKey, setRatingKey] = useState(0);

  useEffect(() => {
    const handleRefresh = () => setRatingKey((prevKey) => prevKey + 1);

    window.addEventListener("refreshRating", handleRefresh);
    return () => window.removeEventListener("refreshRating", handleRefresh);
  }, []);

  return ratingKey;
}
