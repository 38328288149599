import { v4 as uuidv4 } from "uuid";

export const getOrCreateIdentifier = (): string => {
  let identifier = localStorage.getItem("uniqueIdentifier");

  if (!identifier) {
    identifier = uuidv4();
    localStorage.setItem("uniqueIdentifier", identifier);
  }

  return identifier;
};
