import Image from "next/image";
import jdihn from "@/public/assets/images/jdihn.png";
import baliprov from "@/public/assets/images/baliprov.png";
import clsx from "clsx";
import Sidebar from "./sidebar";

const Logo = ({
  variant = "header",
  width = 50,
  height = 50,
  sidebar = false,
}) => {
  const logoClass = clsx("flex", {
    "max-md:hidden": variant === "header",
    "": variant === "footer",
  });
  return (
    <div className={logoClass}>
      <Image src={jdihn} width={width} height={height} alt="${logo} Logo" />
      <Image src={baliprov} width={width} height={height} alt="${logo} Logo" />
    </div>
  );
};

export default Logo;
