import { useEffect, useState } from "react";
import { indonesiaMonth } from "@/src/helpers/dateHelper";
import { AnalyticsModel } from "@/src/models/analyticsModel";
import AnalyticsRepository from "@/src/repositories/analyticsRepository";

export const WebVisitor = () => {
  const [analytics, setAnalytics] = useState<AnalyticsModel | null>(null);
  const [error, setError] = useState<string | null>(null);

  const now = new Date();
  const analyticsRepository = new AnalyticsRepository();

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await analyticsRepository.fetchAnalytics();
        setAnalytics(data.data);
      } catch (error) {
        console.error(error);
      }
    };

    getData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!analytics) {
    return (
      <div className="flex flex-col gap-2">
        <span className="h-2 w-52 animate-pulse rounded-md bg-[#ededef]"></span>
        <span className="h-2 w-56 animate-pulse rounded-md bg-[#ededef]"></span>
        <span className="h-2 w-60 animate-pulse rounded-md bg-[#ededef]"></span>
      </div>
    );
  }

  return (
    <div>
      <ul className="space-y-2">
        <li>
          <p>Hari ini : {analytics.daily}</p>
        </li>

        <li>
          <p>
            Bulan ini ({indonesiaMonth(now)}) : {analytics.monthly}
          </p>
        </li>
        <li>
          <p>Total : {analytics.total}</p>
        </li>
      </ul>
    </div>
  );
};
