import React from "react";
import Link from "next/link";

interface SidebarProps {
  children: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  return (
    <div className="fixed inset-0 z-50 flex">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative w-64 bg-white shadow-lg">
        <div className="flex flex-col px-1 py-4">{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
