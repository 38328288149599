import Logo from "@/src/components/atoms/logo";

const SiteTitle = ({ sidebar = false, variant = "header" }) => {
  return (
    <div className="flex items-center max-md:flex-col max-md:items-start">
      <Logo sidebar={sidebar} variant={variant} />
      <span className="pl-2">JDIH DPRD Provinsi Bali</span>
    </div>
  );
};

export default SiteTitle;
