import React, { FC, useEffect, useState } from "react";

interface StarProps {
  rating: number;
  disable?: boolean;
  setRating: (rating: number) => void;
  hasError: boolean;
}

const StarInput: FC<StarProps> = ({
  rating,
  disable = true,
  setRating,
  hasError = false,
}) => {
  const [localRating, setLocalRating] = useState(rating);

  // Update the local rating when the prop changes
  useEffect(() => {
    setLocalRating(rating);
  }, [rating]);

  // Handle rating change when a star is clicked
  const handleRatingChange = (newRating: number) => {
    if (!disable) {
      // console.log(`rating is ${newRating}`);
      setLocalRating(newRating); // Update local rating
      setRating(newRating); // Update parent rating
    }
  };

  return (
    <div className="comment-stars flex gap-0.5">
      {Array.from({ length: 5 }, (_, i) => {
        const value = i + 1;

        return (
          <span
            key={`star-${value}`}
            className={`icon icon-star ${value <= localRating ? "filled" : ""} ${
              !disable ? "clickable" : ""
            }`}
            onClick={() => handleRatingChange(value)}
          >
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 28"
              fill="currentColor"
              stroke={hasError && localRating === 0 ? "red" : "none"}
              strokeWidth={hasError && localRating === 0 ? "1" : "0"}
            >
              <path d="M26 10.109c0 0.281-0.203 0.547-0.406 0.75l-5.672 5.531 1.344 7.812c0.016 0.109 0.016 0.203 0.016 0.313 0 0.406-0.187 0.781-0.641 0.781-0.219 0-0.438-0.078-0.625-0.187l-7.016-3.687-7.016 3.687c-0.203 0.109-0.406 0.187-0.625 0.187-0.453 0-0.656-0.375-0.656-0.781 0-0.109 0.016-0.203 0.031-0.313l1.344-7.812-5.688-5.531c-0.187-0.203-0.391-0.469-0.391-0.75 0-0.469 0.484-0.656 0.875-0.719l7.844-1.141 3.516-7.109c0.141-0.297 0.406-0.641 0.766-0.641s0.625 0.344 0.766 0.641l3.516 7.109 7.844 1.141c0.375 0.063 0.875 0.25 0.875 0.719z"></path>
            </svg>
          </span>
        );
      })}
      <style jsx>{`
        .comment-stars {
          display: flex;
          flex-direction: row;
        }
        .icon {
          width: 24px;
          height: 24px;
          cursor: default;
          color: #ededef;
        }
        .icon.filled {
          color: gold; /* Filled color for stars */
        }
        .icon.clickable {
          cursor: pointer; /* Show pointer cursor when clickable */
        }
      `}</style>
    </div>
  );
};

export default StarInput;
