// utils/formatDate.ts

import { format } from "date-fns";
import { id } from "date-fns/locale";

export function DateHelper(dateString?: string): string {
  if (!dateString) {
    return "Invalid date";
  }
  const date = new Date(dateString);
  return format(date, "EEEE, d MMMM yyyy", { locale: id });
}

export const indonesiaMonth = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat("id-ID", {
    month: "long",
    year: "numeric",
  });

  return formatter.format(date);
};

export function DateOnlyHelper(dateString?: string): string {
  if (!dateString) {
    return "-";
  }
  const date = new Date(dateString);
  return format(date, "d MMMM yyyy", { locale: id });
}

export function OperationalHourHelper(
  hourFrom: string | null,
  hourTo: string | null,
): string {
  const formatTime = (time: string | null): string => {
    if (!time) {
      return "libur";
    }

    const [hours, minutes, seconds] = time.split(":");

    // If seconds are "00", return the time without seconds
    if (seconds === "00") {
      return `${hours}:${minutes}`;
    }

    return time; // Otherwise, return the time as it is
  };

  // Format both times
  const formattedTime1 = formatTime(hourFrom);
  const formattedTime2 = formatTime(hourTo);

  // If either is "libur", return just "libur"
  if (formattedTime1 === "libur" || formattedTime2 === "libur") {
    return "Libur";
  }

  return `${formattedTime1} - ${formattedTime2}`;
}
