import React, { useState, useRef, useEffect } from "react";
import Link from "next/link";
// import Sidebar from "@/src/components/organisms/sidebar";
import mergeRefs from "@/src/hooks/mergeRefs";
import SiteTitle from "@/src/components/molecules/siteTitle";
import Sidebar from "@/src/components/atoms/sidebar";
import Logo from "@/src/components/atoms/logo";
import LogoSide from "../atoms/logoSide";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  // const combinedRef = mergeRefs(dropdownRef, sidebarRef, aboutRef);
  // const combinedAboutRef = mergeRefs(aboutRef, sidebarRef);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const sidebarMenu = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleAboutDropdown = () => {
    setIsAboutDropdownOpen(!isAboutDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
    if (aboutRef.current && !aboutRef.current.contains(event.target as Node)) {
      setIsAboutDropdownOpen(false);
    }
    // Close sidebar or other dropdowns if needed
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <nav
        className={`z-40 w-full bg-white p-4 transition-transform duration-300 ${isScrolled ? "fixed top-0 shadow-md" : "relative"}`}
      >
        <div className="container mx-auto flex items-center justify-between">
          <div className="font-600 text-2xl text-neutral-900">
            <Link href="/">
              <SiteTitle sidebar={true} />
            </Link>
          </div>
          <div className="hidden space-x-4 md:flex">
            <Link href="/" className="nav-link">
              Beranda
            </Link>
            {/* <Link href="/about" className="text-white hover:text-gray-400">Dokumen Hukum</Link> */}
            <div className="relative" ref={dropdownRef}>
              <button onClick={toggleDropdown} className="nav-link">
                Dokumen Hukum
              </button>
              {isDropdownOpen && (
                <div className="absolute left-0 z-40 mt-2 w-48 rounded-md bg-white shadow-lg">
                  <Link
                    href="/dokumen/peraturan"
                    className="block px-4 py-2 text-neutral-900 hover:bg-gray-100"
                  >
                    Peraturan Perundang-undangan
                  </Link>
                  <Link
                    href="/dokumen/monografi"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Monografi Hukum
                  </Link>
                  <Link
                    href="/dokumen/artikel"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Artikel Hukum
                  </Link>
                  <Link
                    href="/dokumen/putusan"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Putusan Pengadilan / Yurisprudensi
                  </Link>
                </div>
              )}
            </div>

            <Link href="/berita" className="nav-link">
              Berita
            </Link>
            {/* <Link href="/tentang-kami" className="nav-link">
              Tentang Kami
            </Link> */}
            <div className="relative z-40" ref={aboutRef}>
              <button onClick={toggleAboutDropdown} className="nav-link">
                Tentang Kami
              </button>
              {isAboutDropdownOpen && (
                <div className="absolute left-0 z-40 mt-2 w-48 rounded-md bg-white shadow-lg">
                  <Link
                    href="/dasar-hukum"
                    className="block px-4 py-2 text-neutral-900 hover:bg-gray-100"
                  >
                    Dasar Hukum
                  </Link>
                  <Link
                    href="/visi-misi"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Visi dan Misi
                  </Link>
                  <Link
                    href="/struktur-pengelola"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Struktur Pengelola
                  </Link>
                  <Link
                    href="/sop"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    SOP
                  </Link>
                </div>
              )}
            </div>
            <Link href="/kontak" className="nav-link">
              Kontak
            </Link>
          </div>
          {/* For Mobile View  */}
          <div className="md:hidden">
            <button
              onClick={sidebarMenu}
              className="text-black focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
      {/* Sidebar for mobile view */}
      {sidebarOpen && (
        <div ref={sidebarRef} className="md:hidden">
          <Sidebar>
            <div className="mb-2 flex flex-col">
              <LogoSide />
              <span className="text-lg font-bold">JDIH DPRD Provinsi Bali</span>
            </div>
            <Link
              href="/"
              className="block px-1 py-2 text-neutral-900 hover:bg-gray-100"
            >
              Beranda
            </Link>
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="inline-flex w-full items-center justify-between px-1 py-2 text-left text-neutral-900 hover:bg-gray-100"
              >
                Dokumen Hukum
                <svg
                  className="mr-2 ms-3 h-2.5 w-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              {isDropdownOpen && (
                <div className="mt-2 w-full bg-white shadow-lg">
                  <Link
                    href="/dokumen/peraturan"
                    className="block px-4 py-2 text-neutral-900 hover:bg-gray-100"
                  >
                    Peraturan Perundang-undangan
                  </Link>
                  <Link
                    href="/dokumen/monografi"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Monografi Hukum
                  </Link>
                  <Link
                    href="/dokumen/artikel"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Artikel Hukum
                  </Link>
                  <Link
                    href="/dokumen/putusan"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Putusan Pengadilan / Yurisprudensi
                  </Link>
                </div>
              )}
            </div>
            <Link
              href="/berita"
              className="block px-1 py-2 text-neutral-900 hover:bg-gray-100"
            >
              Berita
            </Link>
            <div className="relative" ref={aboutRef}>
              <button
                onClick={toggleAboutDropdown}
                className="inline-flex w-full items-center justify-between px-1 py-2 text-left text-neutral-900 hover:bg-gray-100"
              >
                Tentang Kami
                <svg
                  className="mr-2 ms-3 h-2.5 w-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              {isAboutDropdownOpen && (
                <div className="mt-2 w-full bg-white shadow-lg">
                  <Link
                    href="/dasar-hukum"
                    className="block px-4 py-2 text-neutral-900 hover:bg-gray-100"
                  >
                    Dasar Hukum
                  </Link>
                  <Link
                    href="/visi-misi"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Visi dan Misi
                  </Link>
                  <Link
                    href="/struktur-pengelola"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Struktur Pengelola
                  </Link>
                  <Link
                    href="/sop"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    SOP
                  </Link>
                </div>
              )}
            </div>
            <Link
              href="/kontak"
              className="block px-1 py-2 text-neutral-900 hover:bg-gray-100"
            >
              Kontak
            </Link>
          </Sidebar>
        </div>
      )}
    </div>
  );
}
