import { FC } from "react";
import { FaStar } from "react-icons/fa";
import React from "react";
import Star from "@/src/components/atoms/star";
import StarTest from "@/src/components/atoms/starInput";

interface StarRatingProps {
  total: number;
  count: number;
  rating: number;
  canRating: boolean;
  onClick: () => void;
}

function ratingFormat(number: number): string {
  if (Number.isInteger(number)) {
    return number.toString(); // Return as integer if it's a whole number
  } else {
    return number.toFixed(1); // Otherwise, keep one decimal place
  }
}

function roundToNearestHalf(num: number): number {
  return Math.round(num * 2) / 2;
}

const StarRating: FC<StarRatingProps> = ({
  total,
  count,
  rating,
  canRating = true,
  onClick,
}) => {
  const average = total / count;
  const starDisplay = Math.round(average);
  const raterNumber = count.toString();
  const fullStars = ratingFormat(average);
  const roundStars = roundToNearestHalf(average);
  const fixedRating = parseFloat(rating.toFixed(1));

  const formatNumber = raterNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return (
    <div className="items-left flex flex-col">
      <div className="mb-2">
        {canRating == true ? (
          <button
            className="rounded-xl bg-primary px-4 py-2 text-white"
            onClick={onClick}
          >
            <div className="flex items-center justify-center">Beri Rating</div>
          </button>
        ) : (
          <span className="rounded-md bg-primary px-2 py-1 text-xs text-white">
            Terima kasih sudah memberi penilaian
          </span>
        )}
      </div>
      <div className="my-2 flex flex-row flex-wrap">
        <Star rating={roundStars} />
      </div>
      <div>
        <span>
          Bintang {fixedRating} dari 5 (berdasarkan {formatNumber} penilai)
        </span>
      </div>
    </div>
  );
};

export default StarRating;
