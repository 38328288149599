import Image from "next/image";
import jdihn from "@/public/assets/images/jdihn.png";
import baliprov from "@/public/assets/images/baliprov.png";

const LogoSide = ({ width = 50, height = 50, color = "", sidebar = false }) => {
  return (
    <div className="flex">
      <Image src={jdihn} width={width} height={height} alt="${logo} Logo" />
      <Image src={baliprov} width={width} height={height} alt="${logo} Logo" />
    </div>
  );
};

export default LogoSide;
