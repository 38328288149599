import React from "react";
import Backdrop from "@/src/components/atoms/backdrop";
import Dialog from "@/src/components/atoms/dialog";

interface DialogWithBackdropProps {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogWithBackdrop: React.FC<DialogWithBackdropProps> = ({
  title,
  children,
  onClose,
}) => {
  return (
    <>
      <Backdrop onClick={onClose} />
      <Dialog title={title} onClose={onClose}>
        {children}
      </Dialog>
    </>
  );
};

export default DialogWithBackdrop;
